import React, { useEffect, useState } from "react";
import ContactUs from "../components/ContactUs";
import Header from "../components/Header";
import "./css/Products.css";
import Footer from "../components/Footer";
import ProductDescriptionModal from "../components/productDescriptionModal";

export default function Products() {

	const [formName, setFormName] = useState("");
	const [formBusinessName, setFormBusinessName] = useState("");
	const [formCity, setFormCity] = useState("");
	const [formPhone, setFormPhone] = useState("");
	const [formEmail, setFormEmail] = useState("");
	const [modalProductDesc, setModalProductDesc] = useState({});
	const [modalState, setModalState] = useState(false);

	useEffect(() => {

	}, []);


	let products_arr = [
		{
			product_id: 0,
			name: "Smart Link",
			short_desc: "Rupin Smart Link wirelessly connect and control a wide range of rupin smart devices.",
			description: "Rupin Smart Link wirelessly connect and control a wide range of rupin smart devices. It makes your home Rupin Smart. It does not require Home Internet Connection.",
			specification: ["Controls over 50 Appliances", "Wifi - 2.4 GHz"],
			img_url: ["/assets/images/soul.png"],
			thumbnail_url: "/assets/images/soul.png",
			buy_links: {
				"amazon": "",
				"flipkart": "",
			},
			mrp:0,
			price: 0,
			currency: "INR",
		},
		{
			product_id: 0,
			name: "Switch Controller",
			short_desc: "Controls appliances. Upto 18 channels model available.",
			description: "Sits inside swith board, and controls the appliaces connected on the switch board. Change Light Brightness, Change Fan Speed, Control AC or TV, Even unlock the door, with Rupin Mobile App and Alexa voice command.",
			specification: ["Variants - 2,4,8,18 Channels", "Controls Fan Speed with mobile phone and voice command", "Power per channel - 2000 W", "Very low power consumption"],
			img_url: ["/assets/images/rupin_node.png"],
			thumbnail_url: "/assets/images/rupin_node.png",
			buy_links: {
				"amazon": "",
				"flipkart": "",
			},
			mrp:0,
			price: 0,
			currency: "INR",
		},
		{
			product_id: 0,
			name: "Rupin Curtain Automation",
			short_desc: "Control your curtain anytime from anywhere with Rupin Curtain Automation Solution.",
			description: "Control your curtain anytime from anywhere with Rupin Curtain Automation Solution.",
			specification: ["Wifi and remote controlled", "Open / Close Curtain with remote / Rupin Curtains mobile app", "Schedule curtains for open and close", "Torque - 1.2 NM, 2.5 NM", "Input Voltage - 220 VAC", "Warranty - 5 years"],
			img_url: ["/assets/images/curtain_automation.jpg"],
			thumbnail_url: "/assets/images/curtain_automation.jpg",
			buy_links: {
				"amazon": "",
				"flipkart": "",
			},
			mrp:0,
			price: 0,
			currency: "INR",
		},
	];


	function openProductDesc( product ){
		console.log("Open Product Description");
		setModalProductDesc( product );
		setModalState(true);
	}

	return (
		<>
			<div className="App">
				<Header />
			</div>

			<section className="products-cards-section">
				<div className="container">
					<div className="row">
						{
							products_arr.map((product, index) => {
								return <>
									<div key={index} className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
										<div className="product_card" onClick={ () => { openProductDesc( product ) } }>
											<div className="cardThumbArea">
												<img src={ product['thumbnail_url'] }/>
											</div>
											<div className="cardDescArea">
												<h1>{product.name}</h1>
												<p>{product.short_desc}</p>
											</div>
										</div>
										<br/>
									</div>
								</>
							})
						}
					</div>
				</div>
			</section>
			<Footer />

			<ProductDescriptionModal productDesc={ modalProductDesc } modalState={modalState} setModalState={setModalState} />
		</>

	);


}
