import React, { useEffect } from "react";
import Benifits from "../components/Benifits";
import ContactUs from "../components/ContactUs";
import Header from "../components/Header";
import "./css/Home.css";
import Footer from "../components/Footer";
import Team from "../components/Team";

export default function Home() {
	useEffect(() => {

	}, []);

	return (
		<div className="App">
			<Header />

			<section id="home" className="home-part-one">
				<div className="homeTopBackgroundImage">
					<img src={"/assets/images/home.png"} />
				</div>
				<div className="text-area-home">
					<div className="container">
						<div className="row">
							<div className="home-text col-lg-6 md-6 sm-12">
								<h1>Building Next Generation Lifestyle</h1>
								<br />
								<p className="home-text-para">
									From controlling switches to setting timers for appliances,
									you can automate all the home appliances with Rupin Smart
								</p>
								<br />
								<a href="#contact_us"><button className="home-btn">Contact Us</button></a>
								<br />
								<br />
							</div>
							<div className="col-lg-6 md-6 sm-12"></div>
						</div>
					</div>
					<br />
					<br />
					<div className="container">
						<div className="row gx-2 justify-content-between bottom-margin ">
							<div className="col-lg-3 md-6 sm-12 p-3">
								<div className="box-home">
									<h2>Home Automation</h2>
									<p>
										Rupin transforms your home into smart homes. You get
										complete control of your home in your mobile phone. From
										controlling switches to setting timers for appliances, you
										can automate all the home appliances with Rupin Smart.
									</p>
								</div>
							</div>
							<div className="col-lg-3 md-6 sm-12 p-3">
								<div className="box-home">
									<h2> IOT & AI Support</h2>
									<p>
										Rupin Smart Home leverages the Power of IOT and AI to make
										your home experience richer.
									</p>
								</div>
							</div>
							<div className="col-lg-3 md-6 sm-12 p-3">
								<div className="box-home">
									<h2>Remote Control</h2>
									<p>
										With Rupin App you can add any number of appliances in your
										home. You can control any appliances from anywhere in the
										world using Rupin App.
									</p>
								</div>
							</div>
							<div className="col-lg-3 md-6 sm-12 p-3">
								<div className="box-home">
									<h2>Security</h2>
									<p>
										Its secured with Advanced Encryption Standard. Give us a
										call or Contact any dealer to transform your home into smart
										home.
									</p>
								</div>
							</div>
						</div>
					</div>
					{/* <div className="logo-image">
            <img
              src="/assets/images/logo.svg"
              alt="logo"
              style={{ width: "56px" }}
            />
            <div className="home-text" data-scroll data-scroll-speed="1">
              <h2 data-scroll data-scroll-speed="1">
                Building Next Generation Lifestyle
              </h2>
              <p data-scroll data-scroll-speed="1">
                From controlling switches to setting timers for appliances, you
                can automate all the home appliances with <em>Rupin Smart</em>
              </p>
              <button className="contact-btn" data-scroll data-scroll-speed="1">
                Contact Us
              </button>
              <br />
            </div>
          </div>
          {/* <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12"> */}
					{/* <div className="home-image" data-scroll data-scroll-speed="1">
            <img src="/assets/images/home.png" alt="logo" />
          </div> */}
					{/* </div> */}
				</div>
			</section>
			<br />
			<br />
			<br />
			<br />
			<br />

			<section>
				<div
					className="home-part-two container"
					data-scroll
					data-scroll-speed="1"
				>
					<h3
						data-scroll
						data-scroll-speed="1"
						data-scroll-class="appear"
						data-scroll-repeat="true"
					// data-scroll-direction="horizontal"
					>
						<span className="span-text">A smart home is the future.</span> An
						automation system will monitor and/or control home attributes such
						as lighting, climate, entertainment systems, and appliances. It
						includes home security such as access control and alarm systems.
					</h3>
				</div>
			</section>
			<br />
			<br />
			<br />
			<br />
			<br />

			<section id="features" className="home-part-three container" data-scroll-section>
				<h3 data-scroll data-scroll-speed="1" data-scroll-repeat="true">
					Benifits of having Rupin in Home
				</h3>
				{/* <div className="benifits-section"> */}
				<div className="benifits-section">
					<Benifits
						text=" Your Home becomes secure with Rupin Smart Security System. Monitor
          security camera from anywhere with Rupin Mobile App. You can control
          guest access of your home wirelessly from anywhere in the world."
						image="/assets/images/secure.png"
						title="Secure"
					/>
					<Benifits
						text="You can monitor your energy usage, and optimize to reduce electricity bill. Rupin helps you save 20-25% on electricity bills."
						image="/assets/images/energy.png"
						title="Energy Saver"
					/>

					<Benifits
						text="You can Regulate your fan or control brightness of your lights, you can create ambience as per your mood."
						image="/assets/images/diming.png"
						title="Diming Feature"
					/>

					<Benifits
						text="Control your appliances with mobile application from anywhere and with voice command using alexa or google home."
						image="/assets/images/easy.png"
						title="Easy to Access"
					/>

					<Benifits
						text="Your home can sense temperature, humidity, light intensity and smoke in case of fire. Rupin Helps you control ambience in your home."
						image="/assets/images/temp.png"
						title="Get your home senses."
					/>

					<Benifits
						text="Get alerts in case of fire, intrusion and over usage of appliances. Get suggestions to reduce your electricity bills."
						image="/assets/images/secure.png"
						title="Get Alerts"
					/>

					<Benifits
						title="Easy Installation"
						text="Convert your ordinary home into smart home in 60 Minutes."
						image="/assets/images/secure.png"
					/>

					<Benifits
						title="Set Timer"
						text="Set timer to start or stop any appliance. No need to worry if you forgot to switch off lights."
						image="/assets/images/timer.png"
					/>

					<Benifits
						title="24 x 7 Service"
						text="We are just one call away for any service, any time. We are available 24 x 7 to assist our customers."
						image="/assets/images/smoke.png"
					/>
				</div>
			</section>
			<br />
			<br />

			{/* </div> */}
			<section data-scroll-section className="home-part-four container">
				<img
					src="/assets/images/alexa.png"
					alt="alexa"
					className="alexa-img"
					data-scroll
					data-scroll-speed="1"
				/>
				<div>
					<h1 data-scroll data-scroll-speed="1">
						<em>“Alexa Turn on the Fan in my bedroom”</em>
					</h1>
					<h3 data-scroll data-scroll-speed="1">
						Easy Integration with Amazon Alexa and Google Home. Now give your
						commands with your voice assistant.
					</h3>
					<h3 data-scroll data-scroll-speed="1">
						Change Light Brightness, Change Fan Speed, Control AC or TV, Even
						unlock the door, by just a voice command.
					</h3>
				</div>
			</section>
			<br />
			<br />

			<section id="products" className="our-products" data-scroll-section>
				<div className="container">
					<h1 className="container">Our Products</h1>
					<div className="px-4 gx-5 gy-5 overflow-hidden margin-top-6 products-column">
						<div className="row gx-5 gy-5">
							<div className="col-lg-4">
								<img
									src="/assets/images/soul.png"
									alt="Smart Link"
									className="soul-image"
								/>
							</div>
							<div className="col-lg-8 align-self-center">
								<div>
									<h2>Smart Link</h2>
									<br />
									<h3>
										Smart Link acts as the CPU of smart home system. It can
										connect upto 64 Rupin Switch Controllers. It does not
										require Home Internet Connection.
									</h3>
									<br />
									<h3>
										Specifications
										<br />
										Controls over 50 Appliances
										<br />
										Wifi - 2.4 GHz
										<br />
										Dimension - 100mm x 100mm x 40mm
									</h3>
								</div>
							</div>
							{/* <div className="p-3">Custom column padding</div> */}
							{/* </div> */}
						</div>
					</div>

					<div className="px-1 gx-5 gy-5 overflow-hidden">
						<div className="row justify-content-between">
							<div className="col-lg-6 gy-5 justify-content-between pr-3">
								<div className="p-5 products-column-2">
									<img
										src="/assets/images/sense.png"
										alt="Rupin Sensors"
										className="sense-image"
									/>
									<div>
										<h2>Rupin Sense</h2>
										<p>Comes with Human presense Sensor, Smoke Detector, Temperature Sensor and Humidity Sensor. Turn off appliances when you leave room and save electricity.</p>
									</div>
								</div>
							</div>
							{/* <div className="col-lg-2" /> */}
							<div className="col-lg-6 gy-5 mx-auto justify-content-between p-0 pl-3">
								<div className="p-5 products-column-2">
									<img
										src="/assets/images/rupin_touch.png"
										alt="Rupin Touch Switches"
										className="sense-image"
									/>
									<div>
										<h2>Rupin Touch</h2>
										<p>Rupin touch panels replaces your old mechanical switches, it comes in standard modular designs. Easy to fit and available in multiple colors. Perfectly goes with your amazing interior.</p>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="px-4 gx-5 gy-5 overflow-hidden margin-top-6 products-column">
						<div className="row gx-5 gy-5 ">
							{/* <div className="row products-column gx-5 gy-5"> */}
							<div className="col-lg-4 ">
								<img
									src="/assets/images/rupin_node.png"
									alt="Rupin Soul"
									className="soul-image"
								/>
							</div>
							<div className="col-lg-8 align-self-center">
								<div>
									<h2>Rupin Node-Switch Controller</h2>

									<br />
									<h3>
										Fits inside the switch board
										<br />
										Variants - 2,4,8,18 Channels
										<br />Change Light Brightness, Change Fan Speed, Control AC or TV, Even unlock the door, by just a voice command.
										Control one Appliance per channel
										<br />
										Regulator / Dimmer on all the channels
										<br />
										Power per channel - 1500 W<br />
										Compatible with Regular Mechanical Switches and Rupin Touch
										<br />
										Control with Mobile Application and voice Assistant like
										Alexa/Google Home
										<br />
									</h3>
								</div>
							</div>
						</div>
					</div>
					{/* Products - LED Strip ********************** */}
					<div className="px-4 gx-5 gy-5 overflow-hidden margin-top-6 products-column led_strip_product_column">
						<div className="row gx-5 gy-5 ">
							<div className="col-lg-4 ">
								<img
									src="/assets/images/curtain_motor_wb.png"
									alt="Rupin Soul"
									className="soul-image"
								/>
							</div>
							<div className="col-lg-8 align-self-center">
								<div>
									<h2>Rupin Curtain Automation</h2>
									<br />
									<h3>
										Control your curtain anytime from anywhere with Rupin Curtain Automation Solution.
										<br /><br />
										Rupin curtain motors comes in two varients.
										<ul>
											<li>Wifi and remote controlled</li>
											<li>Remote controlled</li>
										</ul>
										Features
										<ul>
											<li>Open / Close Curtain with remote / Rupin Curtains mobile app</li>
											<li>Schedule curtains for open and close</li>
											<li>Torque - 1.2 NM, 2.5 NM</li>
											<li>Input Voltage - 220 VAC</li>
										</ul>
										Warranty - 5 years
										<br />
										Get full support for planning, installation, and post installation service.
										<br />
									</h3>
									<br />
									{/* <div className="led_strip_color_section">
										<h3>
											Color Choices
										</h3>
										<div className="led_color_area">
											<div className="led_color_box warm_white"></div>
											<div className="led_color_box cool_white"></div>
											<div className="led_color_box blue"></div>
											<div className="led_color_box green"></div>
										</div>
									</div> */}
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<div className="d-flex justify-content-center">
								<a className="learnMoreLink" href="/products">Learn more</a>
							</div>

						</div>
					</div>


				</div>
			</section>
			<br />
			<br />
			<section id="mobile_app" className="mobile-application" data-scroll-section>
				<div className="container">
					<div className="row">
						<h1>Our Mobile Application</h1>
					</div>
					<div className="container">
						<div className="row gx-5 gy-5 p-2">
							<div className="col-lg-4 col-md-4 align-self-center">
								<img src="/assets/images/phone-1-min.png" alt="mobile" />
							</div>
							<div className="col-lg-8 col-md-8 align-self-center">
								<h2>Create Home, add rooms, add appliances.</h2>
								<h3>
									Create Multiple rooms & multiple devices to control by using a
									single smart phone from anywhere in the world
								</h3>
							</div>
						</div>
						<div className="row gx-5 gy-5 p-2 flex-md-row-reverse">
							<div className="col-lg-4 col-md-4 align-self-center">
								<img src="/assets/images/phone-2-min.png" alt="mobile" />
							</div>
							<div className="col-lg-8 col-md-8 align-self-center">
								<h2>
									Add Family members and friends, share access, and control
									access.
								</h2>
								<h3>
									You can control and share access with family and guests.
								</h3>
							</div>
						</div>
						<div className="row gx-5 gy-5 p-2">
							<div className="col-lg-4 col-md-4 align-self-center">
								<img src="/assets/images/phone-3-min.png" alt="mobile" />
							</div>
							<div className="col-lg-8 col-md-8 align-self-center">
								<h2>
									Control Appliances with mobile app from anywhere in the world.
								</h2>
								<h3>
									You can control home appliances from anywhere anytime. Rupin helps you <strong>save electricity bill by 20-25%.</strong>
								</h3>
							</div>
						</div>
						<div className="row gx-5 gy-5 p-2 flex-md-row-reverse">
							<div className="col-lg-4 col-md-4 align-self-center">
								<img src="/assets/images/phone-4-min.png" alt="mobile" />
							</div>
							<div className="col-lg-8 col-md-8 align-self-center">
								<h2>
									Get Alerts on mobile phone for fire, smoke, intrusion, overuse
									of electricity etc.
								</h2>
								<h3>
									You get alerts for any mishappening in your home. Rupin makes your home 360&#xb0; secure.
								</h3>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section id="how_we_work" className="how-we-work" data-scroll-section>
				<div className="container">
					<div className="row">
						<h1>How We Work</h1>
					</div>
					<div className="container">
						<div className="row gx-5 gy-5">
							<div className="col-lg-6">
								<div className="col-12 p-5">
									<h2>Connect</h2>
									<h3>
										Connect with us, we will schedule a meeting with our
										automation expert.
									</h3>
								</div>
								<div className="col-12 p-5">
									<h2>Plan</h2>
									<h3>
										Plan the best automation products for your home with
										features specially tailored for your experience
									</h3>
								</div>
								<div className="col-12 p-5">
									<h2>Install</h2>
									<h3>Our installation team visits, and installs the system</h3>
								</div>
								<div className="col-12 p-5">
									<h2>Enjoy</h2>
									<h3>You have now converted your home into smart home.</h3>
								</div>
							</div>
							<div className="col-lg-6 p-5 align-self-center">
								<img src="/assets/images/group.png" alt="group" />
							</div>
						</div>
					</div>
				</div>
			</section>
			<Team />
			< ContactUs />
			<Footer />
		</div>
	);
}
