import React, { useEffect, useState } from "react";
import ContactUs from "../components/ContactUs";
import Header from "../components/Header";
import "./css/DesignClub.css";
import Footer from "../components/Footer";
import axios from "axios";
import Apiendpoints from "../ApiEndpoints";
import LoaderScreen from "../components/LoaderScreen";

export default function DesignClub() {
	const apiEndpoints = new Apiendpoints();
	const [formName, setFormName] = useState("");
	const [formBusinessName, setFormBusinessName] = useState("");
	const [formCity, setFormCity] = useState("");
	const [formPhone, setFormPhone] = useState("");
	const [formEmail, setFormEmail] = useState("");
	const [contactFormStatus, setContactFormStatus] = useState("");
	const [loaderFlag, setLoaderFlag] = useState(false);

	useEffect(() => {

	}, []);

	function validate_contact_us_form() {
		let response = {};

		response['status'] = 1;
		response['message'] = "";
		response['payload'] = {};

		let name = formName;
		let phone = formPhone;
		let email = formEmail;
		let city = formCity;
		let select_topic = "Rupin Design Club Association";
		let businessName = formBusinessName;

		let payload = {};

		if (name === undefined || name === "") {
			response['message'] = "Please enter name and other details.";
			response['status'] = 0;
			return response;
		}

		if (phone === undefined || phone === "") {
			response['message'] = "Please enter phone and other details";
			response['status'] = 0;
			return response;
		}

		if (city === undefined || city === "") {
			response['status'] = 0;
			response['message'] = "Please enter your city.";
		}

		payload['email'] = email;
		payload['topic'] = select_topic;
		payload['businessName'] = businessName;
		payload['city'] = city;
		payload['name'] = name;
		payload['phone'] = phone;
		payload['topic_code'] = "DESIGNCLUBSUBMIT";
		response['payload'] = payload;
		return response;
	}

	function submitForm() {
		console.log("Submit Form");
		let form_validation = validate_contact_us_form();
		let data = {};
		if (form_validation.status === 1) {
			data = form_validation.payload;
			let endpoint = apiEndpoints.SEND_CONTACT_QUERY;
			let config = {
				headers: {

				},
			};
			console.log("Data for Contact US = ", data, endpoint);
			setLoaderFlag(true);
			axios
				.post(endpoint, data, config)
				.then((res) => {
					setContactFormStatus("Thanks for contacting us. We will connect with you soon.");
					console.log(res);
					setLoaderFlag(false);
				})
				.catch((error) => {
					console.log(error);
					setLoaderFlag(false);
				});
		}
		else {
			console.log(form_validation);
			setContactFormStatus(form_validation['message']);
		}


	}

	return (
		<>
			<Header />
			<div className="container-fluid DC_About_Section">
				<video className="backgroundVideo" src={"/assets/videos/lux_home.mp4"} autoPlay loop muted />
				<div className="row">
					<div className="col-12">
						<div className="DC_about_header">
							<h1>Welcome to
								<br />
								<span className="rupinDesignClubHeading">Rupin Design Club</span>
							</h1>

						</div>
					</div>
				</div>
			</div>
			{/* <br /><br /> */}
			<section className="featuresSection">
				<div className="container-fluid">
					<div className="row">
						<img src={"/assets/images/Group_923.jpg"} />
					</div>
					<div className="row">
						<img src={"/assets/images/Group_925.jpg"} />
					</div>
				</div>
			</section>
			<br /><br /><br />
			<section className="DC_Form_Section">
				<div className="container-fluid">
					<div className="row">
						<div className="col-12">
							<h1>Call us at +91 82873 67057 <br /> or get a callback from us.</h1>
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<div className="formArea">
								<div className="row">
									<div className="col-12">
										<p>Name</p>
										<input type="text" value={formName} onChange={(e) => setFormName(e.target.value)} />
										<p>Business Name ( Optional )</p>
										<input type="text" value={formBusinessName} onChange={(e) => setFormBusinessName(e.target.value)} />
										<p>City</p>
										<input type="text" value={formCity} onChange={(e) => setFormCity(e.target.value)} />
										<p>Phone</p>
										<span className="phoneInputBox"><span className="phoneExtension">+91</span><input type="text" value={formPhone} onChange={(e) => setFormPhone(e.target.value)} /></span>
										<p>Email ( Optional )</p>
										<input type="email" value={formEmail} onChange={(e) => setFormEmail(e.target.value)} />
										<button onClick={submitForm}>Get callback in 24 hours</button>
										<p className="contactFormStatusText">{contactFormStatus}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer />
			{
				loaderFlag ? <>
					<LoaderScreen />
				</> : <></>
			}

		</>

	);


}
